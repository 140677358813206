import React from "react";
import {Form,Row, Col,InputGroup} from 'react-bootstrap';

function InputDateTimePeriod(props) {
    return(
        <Row className='m-3'>
            <Col  xs={12} sm={6}>
                <InputGroup>
                    <InputGroup.Text id={props.id}>
                        <i class={"bi "+props.icon} style={ {color: 'grey' } }/>
                    </InputGroup.Text>

                    <Form.Control id={props.id+"_start"} name={props.id+"_start"} type={props.fieldType} value={props.fieldStartValue}/>
                    <InputGroup.Text> - </InputGroup.Text>
                    <Form.Control id={props.id+"_end"} name={props.id+"_end"} type={props.fieldType} value={props.fieldEndValue}/>                        
                </InputGroup>
            </Col>
        </Row>
    );
}

export default InputDateTimePeriod;