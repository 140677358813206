import React from "react";
import {Stack,Row,Col,Form} from 'react-bootstrap';

function PersonEditSelect(props) {

    function setIcon(){
        return("bi "+props.iconName);
    }

    return(
        <Row className='m-2'>
            <Col xs={12} sm={4} className='h6'>
                <Stack direction="horizontal" gap={1}>
                    <i class={setIcon()} />
                    <div>{props.fieldName}</div>
                </Stack>
            </Col>
            <Col xs={12} sm={4}>
                <Form.Select value={props.fieldSelected}>
                    {props.fieldData?.map((obj) => {
                        return (
                            <option value={obj.id}>{obj.name}</option>
                        )
                    })}
                </Form.Select>
            </Col>
        </Row>
    )
}

export default PersonEditSelect;