import React,{useState} from "react";
import Header from '../../../common/Header';
import {Container,Card} from 'react-bootstrap';
import PageTitle from "../../../common/PageTitle";
import IbotScriptList from "../script/IbotScriptList";
import IbotTemplateNameUpdateDlg from "./IbotTemplateNameUpdateDlg";
import IbotTemplateScriptUpdateDlg from "./IbotTemplateScriptUpdateDlg";

function IbotTemplateEdit(props) {
    const [showTemplateNameUpdateDlg, setShowTemplateNameUpdateDlg] = useState(false);
    const [showTemplateScriptUpdateDlg, setShowTemplateScriptUpdateDlg] = useState(false);
    const [editScriptId, setEditScriptId] = useState(-1);


    const saveTempalteName=(result)=>{
        setShowTemplateNameUpdateDlg(false);
    }

    const clickEditScript=(id=-1)=>{
        setEditScriptId(id);
        setShowTemplateScriptUpdateDlg(true);
    }

    const saveScript=(result)=>{
        setShowTemplateScriptUpdateDlg(false);
    }
    
    const [templateData, setTemplateData] = useState({
        id:2,
        name:"Script 1",
        scripts:[
            {id:1,text:"Hello !",nextScript:2,type:1},
            {id:2,text:"Do you interest in this product?",image:"",nextScript:4,type:2,
                answers:[
                    {id:1,text:"yes",nextScript:3},
                    {id:2,text:"no",nextScript:4},
                ]
            },
            {id:3,text:"How many do you want?",nextScript:4,type:2},
            {id:4,text:"Thanks",type:1}
        ]
    });
    const pageTitle=()=>{
        if(props.templateData)
            return "Update Template - "+props.templateData.name;
        else return "New Template";
    }



    return(
        <>
            <Header />
            <IbotTemplateNameUpdateDlg 
                show={showTemplateNameUpdateDlg} 
                fieldValue={props.templateData?.name} 
                handleCloseClick={()=>{setShowTemplateNameUpdateDlg(false)}}
                handleSaveClick={saveTempalteName}
                />
            <IbotTemplateScriptUpdateDlg 
                show={showTemplateScriptUpdateDlg}
                scripts={templateData?.scripts}
                selectedId={editScriptId}
                handleCloseClick={()=>{setShowTemplateScriptUpdateDlg(false)}}
                handleSaveClick={saveScript}
                />
            <Container>
            <Card>
                    <Card.Body>
                        <PageTitle title={pageTitle()} icon="bi-files" textEdit="true" handleEditClick={()=>{setShowTemplateNameUpdateDlg(true)}}/>

                        <Card className="mb-2">
                            <Card.Body>
                                <IbotScriptList scripts={templateData.scripts} handleScriptEditClick={clickEditScript}/>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default IbotTemplateEdit;