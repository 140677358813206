import React from "react";
import {Form,Row, Col,InputGroup} from 'react-bootstrap';
import ImageUploader from 'react-images-upload';

function InputWhatsappContent(props) {
    function onDrop(pictureFiles, pictureDataURLs) {
        this.setState({
            pictures: pictureFiles
        });
    }
    return(
        <>
            <Row className='m-3'>
                <Col>
                    <ImageUploader
                        withIcon={true}
                        buttonText='Choose images'

                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                        />
                </Col>
            </Row>
            <Row className='m-3'>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Whatsapp Content</InputGroup.Text>
                        <Form.Control as="textarea" aria-label="SMS Content" />
                    </InputGroup>
                </Col>
            </Row>

        </>
    );
}

export default InputWhatsappContent;