import React from "react";
import {Form,Row, Col,InputGroup} from 'react-bootstrap';

function InputSMSContent(props) {
    return(
            <Row className='m-3'>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>SMS Content</InputGroup.Text>
                        <Form.Control as="textarea" aria-label="SMS Content" />
                    </InputGroup>
                </Col>
            </Row>
    );
}

export default InputSMSContent;