import './App.css';

import { RenderRoutes } from "./routes/routes";

import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div>
        <RenderRoutes />
      </div>
    </BrowserRouter>
  );
}

export default App;
