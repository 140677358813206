import React from "react";
import {Form,Row, Col,InputGroup} from 'react-bootstrap';

function InputText(props) {

    const smSize=()=>{
        if((props.lenghtSize)&&(props.lenghtSize ==="lg"))
            return 12;
        else return 6;
    }

    const fieldType=()=>{
        if(props.fieldType)
            return props.fieldType;
        else return "text";
    }
    
    return(
        <Row className='m-3'>
            <Col  xs={12} sm={smSize()}>
                <InputGroup>
                    <InputGroup.Text id={props.id}>
                        <i class={"bi "+props.icon} style={ {color: 'grey' } }/>
                    </InputGroup.Text>
                    <Form.Control
                        type={fieldType()}
                        placeholder={props.placeholder}
                        aria-label={props.id}
                        aria-describedby={props.id}
                        defaultValue={props.fieldValue}
                        /> 
                </InputGroup>
            </Col>
        </Row>
    );
}

export default InputText;