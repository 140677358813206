import React from "react";
import {Stack,Row,Col} from 'react-bootstrap';
import IconButton from "./IconButton";

function PageTitle(props) {
    return(
        <Row>
            <Col>
                <Stack direction="horizontal" gap={3}>
                    <div>
                        <i className={"bi "+props.icon} style={{'fontSize' : '3rem',color:'grey'}}></i>
                    </div>
                    <div className="h1">{props.title}</div> 
                    {(props.textEdit==="true")&&(
                            <IconButton className="m-2" iconName="bi-pen" name="Edit" handleClick={props.handleEditClick}/>
                    )}
                </Stack>
            </Col>
            {props.addNewButton &&(
                <Col className="text-end">
                    <IconButton size="lg" className="m-2" iconName="bi-plus-lg" name="New" handleClick={props.handleNewClick}/>
                </Col>
            )}
        </Row>

    )
}

export default PageTitle;