import React from "react";
import Header from '../../common/Header';
import {Button,Form,Row, Col, Card,Container} from 'react-bootstrap';
import InputText from '../../input/InputText'
import InputDateTimePeriod from "../../input/InputDateTimePeriod";
import InputSelect from "../../input/InputSelect";
import InputMsgContent from "../../input/InputMsgContent";
import PageTitle from "../../common/PageTitle";

function Direct(props) {
    const senderList = [
        {id:1,name:"admin"},
        {id:2,name:"sales"},
        {id:3,name:"info"}
    ];

    const targetGroupList = [
        {id:1,name:"Mid Age Male"},
        {id:2,name:"Mid Age Female"},
        {id:3,name:"Male in Kowloon"}
    ];

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="New Direct Campaign" icon="bi-envelope" />
                        <Card>
                            <Card.Body>
                                <Form>
                                    <InputText id="campaignName" icon="bi-person-vcard" placeholder="Campaign Name" fieldValue=""/>
                                    <InputDateTimePeriod id="campaignDate" icon="bi-calendar-date" fieldType="date" fieldStartValue="" fieldEndValue=""/>
                                    <InputDateTimePeriod id="campaignTime" icon="bi-alarm" fieldType="time" fieldStartValue="" fieldEndValue=""/>
                                    <InputSelect id="campaignSendFrom" icon="bi-postage-fill" placeholder="Select the Sender" fieldValue="" fieldData={senderList}/>
                                    <InputSelect id="campaignTargetGroup" icon="bi-bullseye" placeholder="Select the target group" fieldValue="" fieldData={targetGroupList}/>

                                    <InputMsgContent id="emailContent" icon="bi-envelope-at" fieldName="Email" fieldType="1" />
                                    <InputMsgContent id="smsContent" icon="bi-phone" fieldName="SMS" fieldType="2" />
                                    <InputMsgContent id="whatsappContent" icon="bi-whatsapp" fieldName="Whatsapp" fieldType="3" />
                                    <Row className='m-3'><Col><Button variant="primary" size="lg" href='./main'>Save</Button></Col></Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default Direct;