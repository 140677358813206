import React from "react";
import {Stack,InputGroup,Form} from 'react-bootstrap';
import IconButton from "./IconButton";

function SimpleSearchBox(props) {


    return(
        <InputGroup>
            <InputGroup.Text>
                <Stack direction="horizontal" gap={1}>
                    <i class={"bi "+props.iconName}  style={ { fontSize:'1em', color: 'black' } }/>
                    <div>{props.name}</div>
                </Stack>
            </InputGroup.Text>
            <Form.Control
                id={props.id}
                placeholder={props.placeholder}
                aria-label=""
                aria-describedby=""
                /> 
            <IconButton iconName="bi-search" name="Search" variant="secondary" handleClick={props.handleClick}/>
        </InputGroup>
    )
}

export default SimpleSearchBox;