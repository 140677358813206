import {Container,Navbar,Nav,NavDropdown} from 'react-bootstrap';

function Header(props) {
    return(
        <>
            <Navbar expand="lg" bg="secondary" data-bs-theme="dark">
                <Container fluid>
                    <Navbar.Brand href="#home">Gobihubs</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '200px' }}
                            navbarScroll
                            >
                            <Nav.Link href="dashboard">Dashborad</Nav.Link>
                            <Nav.Link href="personList">Customer</Nav.Link>
                            <Nav.Link href="ibotTemplateMan">Template</Nav.Link>
                            <Nav.Link href="targetGroup">Target Group</Nav.Link>
                            <Nav.Link href="camapign">Campaign</Nav.Link>                         
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse id="navbarScroll" className="justify-content-end m-2">
                        <NavDropdown title="Hi, John" id="navbarScrollingDropdown">
                                <NavDropdown.Item href="/">Logout</NavDropdown.Item>
                        </NavDropdown>                           
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <br />
        </>
    )
}

export default Header;