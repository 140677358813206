import React from "react";
import {Stack,Row,Col,Badge} from 'react-bootstrap';

function PersonEditDimensionList(props) {
    return(
        <Row className='m-2'>
            <Col xs={12} sm={4} className='h6'>
                <Stack direction="horizontal" gap={1}>
                    <i class="bi bi-pie-chart" />
                    <div>Segmentation Group</div>
                </Stack>
            </Col>
            <Col>
                {props.dimension.map((obj) => {
                    return (
                        <Row className="mb-1">
                            <Col>
                                <Badge text="dark" bg="info" pill="true">{obj.name} - {obj.value}</Badge>
                            </Col>
                        </Row>
                    )
                })}
            </Col>
        </Row>
    )
}

export default PersonEditDimensionList;