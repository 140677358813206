import React from "react";
import {Form,Row, Col,InputGroup} from 'react-bootstrap';

function InputSelect(props) {
    const smSize=()=>{
        if((props.lenghtSize)&&(props.lenghtSize ==="lg"))
            return 12;
        else return 6;
    }
    return(
        <Row className='m-3'>
            <Col  xs={12} sm={smSize()}>
                <InputGroup>
                    <InputGroup.Text id={props.id}>
                        <i class={"bi "+props.icon} style={ {color: 'grey' } }/>
                    </InputGroup.Text>

                    <Form.Select aria-label="Default select example" value={props.fieldValue}>
                        <option>{props.placeholder}</option>
                        {props.fieldData?.map((obj) => {
                            return (
                                <option value={obj.id}>{obj.name}</option>
                            )
                        })}
                    </Form.Select> 
                </InputGroup>
            </Col>
        </Row>
    );
}

export default InputSelect;