import React from "react";
import {Stack,Row,Col} from 'react-bootstrap';

function PersonEditText(props) {

    function setIcon(){
        return("bi "+props.iconName);
    }

    return(
        <Row className='m-2'>
            <Col xs={12} sm={4} className='h6'>
                <Stack direction="horizontal" gap={1}>
                    <i class={setIcon()} />
                    <div>{props.fieldName}</div>
                </Stack>
            </Col>
            <Col>
                
                <div className="text-info">{props.fieldValue}</div>
            </Col>
        </Row>
    )
}

export default PersonEditText;