import React, { useState } from "react";
import {Stack,Row, Col,Card} from 'react-bootstrap';
import Switch from 'react-ios-switch';
import InputEmailContent from "./InputEmailContent";
import InputWhatsappContent from "./InputWhatsappContent";
import InputSMSContent from "./InputSMSContent";

function InputMsgContent(props) {
    const normalWidth= 6;
    const fullWidth= 12;
    const [colWidth, setColWidth] = useState(normalWidth);
    const [showBody, setShowBody] = useState(false);

    function toggleShow (){
        setShowBody(!showBody);
        if(showBody)
            setColWidth(normalWidth);
        else setColWidth(fullWidth);
    };

    function getCardBodyContent(){
        switch(Number(props.fieldType)) {
            case 1:
                return <InputEmailContent/>
            case 2:
                return <InputSMSContent/>
            case 3:
                return <InputWhatsappContent/>
                default: 
            return <></>
        }
    }

    return(
        <Row className='m-3'>
            <Col  xs={12} sm={colWidth}>
            <Card>
                <Card.Header>
                <Row>
                    <Col>
                    <Stack direction="horizontal" gap={3}>
                        <i class={"bi "+props.icon} style={ {fontSize:'1.5em',color: 'grey' } }/>
                        <div className="h5">{props.fieldName}</div>
                    </Stack>
                    </Col>
                    <Col className="text-end">
                        <Switch checked={showBody} onChange={toggleShow} onColor="#0d6efd"/>
                    </Col>
                </Row>
                </Card.Header>
                {showBody &&(<Card.Body>
                    {getCardBodyContent()}
                </Card.Body>)}
            </Card>
            </Col>
        </Row>
    );
}

export default InputMsgContent;