import React from "react";
import Header from '../common/Header';
import {Container,Card,Stack,Button} from 'react-bootstrap';
import PageTitle from "../common/PageTitle";
import BootstrapTable from 'react-bootstrap-table-next';

function TargetGroupPersonList(props) {
    //person date
    const data =[
        {id:1,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:2},
        {id:2,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:1},
        {id:3,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:3},
        {id:4,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absvdfdsds@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:2},
        {id:5,firstname:'David',lastname:'Lau',mobile:'85292210000',email:'absv@abc.com',dimension:[{id:1,name:'Gender',value:'Male'}],preferMedia:2},
    ]

    //table column setting
    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true ,
            formatter:nameFormatter          
        }, {
            text: 'mobile',
            formatter:mobileFormatter
        }, {
            text: 'email',
            formatter:emailFormatter
        }, {
            text: 'Media',
            formatter:mediaFormatter
        }]; 

    //name column formatter, use to link up the name and the type
    function nameFormatter(cell, row) {
        return(
            <div>{row.firstname +" "+ row.lastname}</div>
        )
    } 
    
    function mobileFormatter(cell,row){
        return(
            <div>{row.mobile.substring(0,row.mobile.length-4)+'XXXX'}</div>
        )
    }
        
    function emailFormatter(cell,row){

        return(
            <div>{row.email.substring(0,row.email.indexOf('@'))+'@XXXX'}</div>
        )        
    }

    function mediaFormatter(cell,row){
        let icon ="";
        let mediaName ="";

        switch(row.preferMedia) {
            case 1:
                icon="bi bi-envelope-at";
                mediaName='Email';
                break;
            case 2:
                icon="bi bi-envelope";
                mediaName='SMS';
                break;
            case 3:
                icon="bi bi-whatsapp";
                mediaName='Whatsapp';
                break;
            default:
                icon="";
                mediaName='';
          }

        return(
            <Stack direction="horizontal" gap={1}>
                <i class={icon} style={{'font-size' : '1.5rem', color:'black'}}></i>
                <div>{mediaName}</div>
            </Stack>
        )        
    }

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="Customer List" icon="bi-people" />
                            <Card>
                                <Card.Body>

                                    <BootstrapTable 
                                        keyField='id' 
                                        data={ data } 
                                        columns={ columns } 
                                        bordered={ false } 
                                        noDataIndication="Table is Empty" 
                                        hover condensed/>
                                    <Button variant="secondary" href="./targetGroup">Close</Button>
                                </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default TargetGroupPersonList;