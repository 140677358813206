import React from "react";
import {
  Routes,
  Route
} from "react-router-dom";

import Login from "../components/login/Login";
import Campaign from "../components/campaign/Campaign";
import Direct from "../components/channel/direct/Direct";
import TargetGroup from "../components/targetGroup/TargetGroup";
import TargetGroupEdit from "../components/targetGroup/TargetGroupEdit";
import TargetGroupEditPersonList from "../components/targetGroup/TargetGroupPersonList";
import PersonEdit from "../components/person/PersonEdit";
import PersonList from "../components/person/PersonList";
import IbotNewCampaign from "../components/channel/ibot/IbotNewCampaign";
import IbotTemplateMan from "../components/channel/ibot/template/IbotTemplateMan";
import IbotTemplateEdit from "../components/channel/ibot/template/IbotTemplateEdit";
import Dashboard from "../components/dashboard/Dashboard";

export function RenderRoutes({ routes }) {
  return (
    <Routes>
      <Route path="/" key="ROOT" exact element={<Login urlKey="root" />} />
      <Route path="/dashboard" key="ROOT" exact element={<Dashboard urlKey="root" />} />
      <Route path="/main" key="ROOT" exact element={<Dashboard urlKey="root" />} />
      <Route path="/camapign" key="ROOT" exact element={<Campaign urlKey="root" />} />
      <Route path="/targetGroup" key="ROOT" exact element={<TargetGroup urlKey="root" />} />
      <Route path="/targetGroupEdit" key="ROOT" exact element={<TargetGroupEdit urlKey="root" />} />
      <Route path="/targetGroupPersonList" key="ROOT" exact element={<TargetGroupEditPersonList urlKey="root" />} />
      <Route path="/directCampign" key="ROOT" exact element={<Direct urlKey="root" />} />
      <Route path="/ibotCampign" key="ROOT" exact element={<IbotNewCampaign urlKey="root" />} />
      <Route path="/ibotTemplateMan" key="ROOT" exact element={<IbotTemplateMan urlKey="root" />} />
      <Route path="/ibotTemplateEdit" key="ROOT" exact element={<IbotTemplateEdit urlKey="root" />} />
      <Route path="/personEdit" key="ROOT" exact element={<PersonEdit urlKey="root" />} />
      <Route path="/personList" key="ROOT" exact element={<PersonList urlKey="root" />} />

      <Route element={() => <h1>Not Found!</h1>} />
    </Routes>
  );
}