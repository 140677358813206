import React from "react";
import {Card,Row,Col,Badge} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import IconButton from "../common/IconButton";

function TargetGroupItem(props) {
    function totalFormatter(num){
        let rtnFormat='';
        if(num>=1000000){
            rtnFormat=(num/1000000).toFixed(2) +'m';
        }else if(num>=1000){
            rtnFormat=(num/1000).toFixed(2) +'k';
        }else{
            rtnFormat=num.toString();
        }
        return(
            rtnFormat
        );
    }

    const navigate = useNavigate();
    const gotoEdit=()=> {
        navigate('/targetGroupEdit', {state:{targetGroupData:props.targetGroupItem}} );
    };  

 
    return(
        <Col xs={12} sm={12} md={12} lg={5} className="m-1">
            <Card>
                <Card.Header>
                    <Row>
                        <Col className="h5">{props.targetGroupItem.name}</Col>

                        <Col className="text-end">
                            <IconButton className="m-1" size="sm" variant="secondary" iconName="bi-pen" name="Edit" handleClick={gotoEdit}/>
                            <IconButton className="m-1" size="sm" variant="secondary" iconName="bi-trash" name="Delete"/>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="mt-1">
                        <Col className="text-end">
                            <Badge bg="info">{totalFormatter(props.targetGroupItem.total)}</Badge>
                        </Col>   
                    </Row>
                    {
                        props.targetGroupItem.dimension.map((obj)=>(
                            <Row className="mb-1">
                                <Col>{obj.name}</Col>
                                <Col className="text-primary">{obj.value}</Col>
                            </Row>
                        ))
                    }

                </Card.Body>
            </Card>
        </Col>

    )
}

export default TargetGroupItem;