import React from "react";
import {Card,Row,Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import IconButton from "../../../common/IconButton";

function IbotTemplateItem(props) {
    const navigate = useNavigate();
    const gotoEdit=()=> {
        /*{state:{targetGroup:props.targetGroupItem}}*/
        navigate('/ibotTemplateEdit' );
    };  

    const itemName= ()=>{
        if(props.templateItem)
            return props.templateItem.name;
        else return "";
    }

    return(
        <Col xs={12} sm={12} md={12} lg={8} >
            <Card className="m-1">
                <Card.Header>
                    <Row>
                        <Col>
                            <div className="h5">{itemName()}</div>
                        </Col>
                        <Col className="text-end">
                            <IconButton className="m-1" size="sm" variant="secondary" iconName="bi-trash" name="Delete"/>
                            <IconButton className="m-1" size="sm" variant="secondary" iconName="bi-pen" name="Edit" handleClick={gotoEdit}/>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {props.templateItem?.scripts?.map((question,idx)=>(
                        <Row>
                            <Col xs={1}>{idx+1}</Col>
                            <Col className="text-truncate">{question}</Col>
                        </Row>
                    ))}
                </Card.Body>
            </Card>
        </Col>

    )
}

export default IbotTemplateItem;