import React, { useState } from "react";
import Header from '../common/Header';
import BootstrapTable from 'react-bootstrap-table-next';
import {Container,Stack,Button,Modal,Form,InputGroup,Card,Offcanvas,Col,Row} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns/format';
import Switch from 'react-ios-switch';
import PageTitle from "../common/PageTitle";


function Campaign(props) {
    //new Campagin dialog state
    const [showNewCamapign, setShowNewCampaign] = useState(false);
    const handleCloseNewCampaign = () => setShowNewCampaign(false);
    const handleShowNewCampaign = () => setShowNewCampaign(true);

    //Copy to dialog state
    const [showCopyTo, setShowCopyTo] = useState(false);
    const handleCloseCopyTo = () => setShowCopyTo(false);
    const handleShowCopyTo = () => setShowCopyTo(true);

    //Filter dialog state
    const [showFilter, setShowFilter] = useState(false);
    const handleCloseFilter = () => setShowFilter(false);
    const handleShowFilter = () => setShowFilter(true);

    //testing date
    const data =[
        {name:'New Year',startDate:'2024-1-1',endDate:'2024-1-5',status:'P',channelType:1},
        {name:'Easter',startDate:'2024-4-1',endDate:'2024-4-5',status:'L',channelType:3},
        {name:'New Year 2',startDate:'2024-1-1',endDate:'2024-1-5',status:'D',channelType:1},
        {name:'New Year 3',startDate:'2024-1-1',endDate:'2024-1-5',status:'S',channelType:1},
        {name:'New Year 4',startDate:'2024-1-1',endDate:'2024-1-5',status:'F',channelType:1},
    ]

    //new Campaign Selected handling function
    const navigate = useNavigate();
    const newDirectCampaign = (event) => {
        navigate('/directCampign');

    };    

    const newIbotCampaign = (event) => {
        navigate('/ibotCampign');

    };    

    const campaignStatus = {
        "P":"Pending",
        "L":"Locked",
        "D":"Despatching",
        "S":"Suspended",
        "F":"Completed"
    };

    //table column setting
    const columns = [
        {
            dataField: 'name',
            text: 'Campaign Name',
            sort: true ,
            formatter:nameFormatter          
        }, {
            text: 'Date',
            formatter:dtFormatter
        }, {
            dataField: 'status',
            text: 'Status',
            formatter:statusFormatter
        }]; 

    function actionFormatter(cell, row) {
        return(
            <Stack direction="horizontal" gap={1}>
                <div><Button variant="secondary" onClick={handleShowCopyTo}><i class="bi bi-copy" style={{'font-size' : '1rem',color:'white'}}></i></Button></div>
                <div><Button variant="secondary" style={{backgroundColor : '#aaffaa'}}><i class="bi bi-file-check" style={{'font-size' : '1rem',color:'black'}}></i></Button></div>
                <div><Button variant="secondary" style={{backgroundColor : '#ffaaaa'}}><i class="bi bi-file-x" style={{'font-size' : '1rem',color:'black'}}></i></Button></div>
                <div><Button variant="secondary"><i class="bi bi-play-fill" style={{'font-size' : '1rem',color:'white'}}></i></Button></div>
                <div><Button variant="secondary"><i class="bi bi-stop-fill" style={{'font-size' : '1rem',color:'white'}}></i></Button></div>
            </Stack>            
        )
    } 

    //date column formatter, use to change the date format and link up start date and end date
    function dtFormatter(cell, row) {
        return(
            <div>{format(Date(row.startDate), 'do MMMM yyyy')} - {format(Date(row.endDate), 'do MMMM yyyy')}</div>
        )
    } 


    //name column formatter, use to link up the name and the type
    function nameFormatter(cell, row) {
        let icon ="";

        switch(row.channelType) {
            case 1:
                icon="bi bi-envelope-at";
                break;
            case 2:
                icon="bi bi-envelope";
                break;
            case 3:
                icon="bi bi-whatsapp";
                break;
            default:
                icon="bi bi-copy";
              // code block
          }

        return(
            <div>
                <i class={icon} style={{'font-size' : '2rem', color:'black'}}></i>
                <span className="m-2">{cell}</span>
            </div>
        )
    } 

    //status column formatter, use to change the status code and colour
    function statusFormatter(cell, row) {
        let statusColor ="";

        switch(row.status) {
            case 'P':
                statusColor="black";
                break;
            case 'L':
                statusColor="blue";
                break;
            case 'D':
                statusColor="green";
                break;
            case 'S':
                statusColor="red";
                break;
            case 'F':
                statusColor="grey";
                break;
            default:
                statusColor="black";
              // code block
          }

        return(
            <div>
                <span className="m-2" style={{color:statusColor}}>{campaignStatus[cell]}</span>
            </div>
        )
    } 

    return(
        <>
            <Header />
            <Offcanvas show={showFilter} onHide={handleCloseFilter} backdrop="false" backgroundColor="grey" >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <i class="bi bi-funnel" style={{'font-size' : '2rem',color:'grey'}}></i>
                        <span className="h4">Campaign Filter</span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="mb-2">
                        <Card.Body>
                            <InputGroup className="mb-2">
                                <InputGroup.Text id="campaignName">
                                    <i class="bi bi-person-vcard" style={ {color: 'grey' } }/>
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Campaign Name"
                                    aria-label="campaignName"
                                    aria-describedby="campaignName"
                                    /> 
                            </InputGroup>
                            <InputGroup className="mb-2">
                                <InputGroup.Text id="startdate">
                                    <i class="bi bi-calendar-date" style={ {color: 'grey' } }/>
                                </InputGroup.Text>
                                <Form.Control type="date" />
                                <InputGroup.Text> - </InputGroup.Text>
                                <Form.Control type="date" />
                            </InputGroup>
                            <Stack  direction="horizontal" gap={3}>
                                <div>Show active only</div>
                                <Switch checked={true} onChange={checked => {}} />
                            </Stack>
                        </Card.Body>
                    </Card>
                    <Card className="mb-2">
                        <Card.Body>
                            <Row>
                                <Col><div className="h4">Campaing Type</div></Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Stack direction="horizontal" gap={1}>
                                        <i class="bi bi-envelope-at" style={{'font-size' : '1.5rem', color:'black'}}></i>
                                        <div>Email</div>
                                    </Stack>
                                </Col>
                                <Col><Switch checked={true} onChange={checked => {}} /></Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Stack direction="horizontal" gap={1}>
                                        <i class="bi bi-envelope" style={{'font-size' : '1.5rem', color:'black'}}></i>
                                        <div>SMS</div>
                                    </Stack>
                                </Col>
                                <Col><Switch checked={true} onChange={checked => {}} /></Col>
                            </Row> 
                            <Row className="mb-1">
                                <Col>
                                    <Stack direction="horizontal" gap={1}>
                                        <i class="bi bi-whatsapp" style={{'font-size' : '1.5rem', color:'black'}}></i>
                                        <div>whatsapp</div>
                                    </Stack>
                                </Col>
                                <Col><Switch checked={true} onChange={checked => {}} /></Col>
                            </Row> 
                        </Card.Body>
                    </Card>
                </Offcanvas.Body>
            </Offcanvas> 


            <Modal show={showCopyTo} onHide={handleCloseCopyTo}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Stack direction="horizontal" gap={3}>
                            <div className="p-1"><i class="bi bi-copy" style={{'font-size' : '2rem',color:'grey'}}></i></div>
                            <div className="p-1 h2">Copy To...</div>                
                        </Stack>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="m-2">
                    <InputGroup.Text> Convert to : </InputGroup.Text>
                        <Form.Select aria-label="Select the language">
                            <option>Select the language</option>
                            <option value="1">English</option>
                            <option value="2">繁體中文</option>
                            <option value="3">簡體中文</option>
                        </Form.Select>   
                    </InputGroup>                 
                    <InputGroup className="m-2">
                        <InputGroup.Text id="campaignName">
                            <i class="bi bi-person-vcard" style={ {color: 'grey' } }/>
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="New Campaign Name"
                            aria-label="campaignName"
                            aria-describedby="campaignName"
                            /> 
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCopyTo}>Cancel</Button>
                    <Button variant="primary" onClick={handleCloseCopyTo}>Copy</Button>
            </Modal.Footer>
            </Modal>


            <Modal show={showNewCamapign} onHide={handleCloseNewCampaign}>
                <Modal.Header closeButton>
                    <Modal.Title>Select New Campagin Channel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <Row className="m-2">
                                <Col><Button variant="outline-secondary" style={{width:"100%"}} onClick={newDirectCampaign}>
                                        <i class="bi bi-envelope"></i>
                                        <span> Direct Campaign</span>
                                </Button></Col>
                            </Row>
                            <Row className="m-2">
                                <Col><Button variant="outline-secondary" style={{width:"100%"}} onClick={newIbotCampaign}>
                                        <i class="bi bi-chat-text"></i>
                                        <span> iBot Campaign</span>
                                </Button></Col>
                            </Row>                            
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>


            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="Campaign Management" icon="bi-megaphone" addNewButton="true" handleNewClick={handleShowNewCampaign} />
                        <Card className="mb-1">
                            <Card.Body>
                                <Stack direction="horizontal" gap={1}>
                                    <Button size='sm' variant="info" onClick={handleShowFilter}>
                                        <i class="bi bi-funnel" style={{color:'black'}}></i>
                                        <span className="ml-1">Filter</span>
                                    </Button>
                                    <Button size='sm' variant="outline-info" onClick={handleShowFilter}>Date: 24th June 2024</Button>
                                    <Button size='sm' variant="outline-info" onClick={handleShowFilter}>Status: Active</Button>
                                    <Button size='sm' variant="outline-info" onClick={handleShowFilter}>Name: Year</Button>
                                </Stack>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>                            
                                <BootstrapTable 
                                    keyField='name' 
                                    data={ data } 
                                    columns={ columns } 
                                    bordered={ false } 
                                    noDataIndication="Table is Empty" 
                                    hover condensed/>
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>

            </Container>

        </>
    )
}

export default Campaign;