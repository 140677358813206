import React from "react";
import {Stack,Row,Col} from 'react-bootstrap';
import Switch from 'react-ios-switch';

function PersonEditPermissionEdit(props) {
    return(
        <Row className='m-2'>
            <Col xs={12} sm={4} className='h6'>
                <Stack direction="horizontal" gap={1}>
                    <i class="bi bi-person-lock" />
                    <div>Permission</div>
                </Stack>
            </Col>
            <Col>
                {props.permission.map((obj) => {
                    return (
                        <Row className="mb-1">
                            <Col>{obj.name}</Col>
                            <Col>
                                <Switch checked={obj.value} onChange={checked => {}} onColor="#0d6efd"/>
                            </Col>
                        </Row>
                    )
                })}
            </Col>
        </Row>
    )
}

export default PersonEditPermissionEdit;