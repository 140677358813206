import React,{useState} from "react";
import Header from '../../../common/Header';
import {Container,Card,Stack,InputGroup,Form,Row,Col} from 'react-bootstrap';
import PageTitle from "../../../common/PageTitle";
import IbotTemplateList from "./IbotTemplateList";
import SimpleSearchBox from "../../../common/SimpleSearchBox";

function IbotTemplateMan(props) {
    const [searchData, setSearchData] = useState([
        {id:1,name:'Spring Sales',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:2,name:'Spring Sales 2',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:3,name:'Spring Sales 3',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:4,name:'Spring Sales 4',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:5,name:'Spring Sales 5',scripts:["What is your age range (For testign the truncate funciton)?","What is your gender?","What is your favour colour?"]}
    ]);

    const updateSearchResult=()=>{
        setSearchData(data);
    }

    const clearSearchResult=()=>{
        setSearchData([]);
    }

    const getResultStatus=()=>{
        if(searchData.length>0)
            return true;
        else return false;
    }

    //person date
    const data =[
        {id:1,name:'Spring Sales',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:2,name:'Spring Sales 2',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:3,name:'Spring Sales 3',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:4,name:'Spring Sales 4',scripts:["What is your age range?","What is your gender?","What is your favour colour?"]},
        {id:5,name:'Spring Sales 5',scripts:["What is your age range (For testign the truncate funciton)?","What is your gender?","What is your favour colour?"]}
    ]

    return(
        <>
            <Header />
            <Container>
                <Card>
                    <Card.Body>
                        <PageTitle title="iBot Template" icon="bi-files" addNewButton="true" />
                        <Card className="mb-2">
                            <Card.Body>
                                <Row className='m-1'>
                                    <Col xs={12} sm={6} md={7}>
                                        <SimpleSearchBox 
                                            id="templateSearch" 
                                            name="Template" 
                                            iconName="bi-files" 
                                            placeholder="Template Name" 
                                            handleClick={updateSearchResult}/>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {getResultStatus() && (
                            <Card>
                                <Card.Body>
                                    <IbotTemplateList templateData={searchData} />
                                </Card.Body>
                            </Card>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default IbotTemplateMan;