import React,{useState} from "react";
import IbotScriptItem from "./IbotScriptItem";

function IbotScriptList(props) {
    const [selectedScriptId, setSelectedScriptId] = useState(-1);
    const [selectedScriptDefaultNextId, setSelectedScriptDefaultNextId] = useState(-1);
    const [selectedScriptAnswerNextId, setSelectedScriptAnswerNextId] = useState([]);


    const isSelectedNextScript =(id)=>{
        if(selectedScriptDefaultNextId===id)
            return true;
        else return false;

    };

    const scriptSelectedChg =(id)=>{
        setSelectedScriptId(id);
        props.scripts.forEach((script)=>{
            if(script.id===id){
                setSelectedScriptDefaultNextId(script.nextScript)
                let answerNextId=[];
                script.answers?.forEach((answer,idx)=>{
                    if(answer.nextScript)
                        answerNextId.push({id:answer.nextScript,pos:idx});
                })

                setSelectedScriptAnswerNextId(answerNextId);
            }
        })
    }

    return(
        <>
            {
                props.scripts.map((script,idx)=>(

                    <IbotScriptItem 
                        scriptData={script} 
                        selectedScriptId={selectedScriptId}
                        answersNextId={selectedScriptAnswerNextId}
                        handleSelectedScriptId={scriptSelectedChg}
                        isNextDefaultScript={isSelectedNextScript(script.id)}
                        handleScriptEditClick={(id)=>{props.handleScriptEditClick(id)}}
                        />
                ))
            }
            <IbotScriptItem isNew="true" handleScriptEditClick={(id)=>{props.handleScriptEditClick(id)}}/>
        </>
        
    )
}

export default IbotScriptList;