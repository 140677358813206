import {Container,Row,Col,Navbar,Card} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import PageTitle from  "../common/PageTitle";
import InputText from '../input/InputText';
import IconButton from '../common/IconButton';

function Login() {
    const navigate = useNavigate();
    const gotoEdit=()=> {
        navigate('/main' );
    };  
    return (
        <>
            <Navbar bg="secondary" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand href="#home">Gobihubs</Navbar.Brand>
                </Container>
            </Navbar>
            <br />
            <Container >
                <Row>
                    <Col xs={0} sm={1} md={2} lg={3}></Col>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Card>
                            <Card.Body>
                                <PageTitle title="Gobihubs Management System" icon="bi-bullseye" />

                                <Card>
                                    <Card.Body>
                                        <InputText id="username" icon="bi-person-fill" placeholder="User Name" lenghtSize="lg"/>
                                        <InputText id="password" icon="bi-key-fill" placeholder="Password" fieldType="password" lenghtSize="lg" />
                                        <Row className='m-3'>
                                            <Col className='text-end'><IconButton iconName="bi-box-arrow-in-right" name="Login" handleClick={gotoEdit}/></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col  xs={0} sm={1} md={2} lg={3} ></Col>
                </Row>
            </Container>
        </>
    );
}

export default Login;