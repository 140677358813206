import React from "react";
import {Row} from 'react-bootstrap';
import IbotTemplateItem from "./IbotTemplateItem";

function IbotTemplateList(props) {
    return(
        <Row className='m-1'>
            {
                props.templateData?.map((obj)=>(
                    <IbotTemplateItem templateItem={obj} />
                ))
            }  
        </Row>
    )
}

export default IbotTemplateList;