import React from "react";
import {Form,Row, Col,InputGroup} from 'react-bootstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

function InputEmailContent(props) {
    return(
        <>
            <Row className='m-3'>
                <Col>
                    <InputGroup>
                        <InputGroup.Text id="emailSubject">
                            Subject
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="Email Subject"
                            aria-label="emailSubject"
                            aria-describedby="emailSubject"
                            /> 
                    </InputGroup>
                </Col>
            </Row>
            <Row className='m-3'>
                <Col>
                    <SunEditor height="250px" placeholder="Email Content..." />
                </Col>
            </Row>
        </>
    );
}

export default InputEmailContent;